import styled from "styled-components";

export default function Info() {
    const Info = styled.div`
    white-space: pre-wrap;
    display: inline;
    max-width: 45rem;

    `
    
    return (
        <Info id="info"></Info>
    )
}